import React, {useEffect, useMemo, useState} from 'react';
import {GradientButton} from "./GradientButton";
import {QrMemoriesContextDto} from "../App";
import {LargeHeaderV2} from "./LargeHeaderV2";

interface WelcomeScreenProps {
    isMobile: boolean;
    context: QrMemoriesContextDto;
    getRootProps: any;
    getInputProps: any;
    onNameUpdated: (name: string) => void;
    code: string;
}

export const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ code, isMobile, context, getRootProps, getInputProps, onNameUpdated }: WelcomeScreenProps) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [name, setName] = useState<string>('');
    const [consentGiven, setConsentGiven] = useState<boolean>(false);
    const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const isDemo = useMemo(() => code === 'demo', [code]);

    useEffect(() => {
        setIsFormValid(!!(name.length && consentGiven));
        if (name.length) {
            onNameUpdated(name);
        }
    }, [name, consentGiven])

    return (
        // min-h-screen
        <div className="flex flex-col bg-gray-100 ">
            <LargeHeaderV2 isMobile={isMobile} context={context} betaDisclaimer={false} demoDisclaimer={isDemo} />

            <div className="-mt-4 z-[10] flex flex-col items-center justify-between min-h-full p-4 max-w-screen-sm mx-4 bg-white border rounded-xl">
                <div className="w-full">
                    <h2 className="text-lg font-light">Jak masz na imię?</h2>
                    {/*Ania - text-sm or not? */}
                    {/*<p className="mb-2 font-light opacity-60 text-sm">Dzięki temu organizatorzy dowiedzą się, kto przekazał tę pamiątkę</p>*/}
                    <p className="mb-2 font-light opacity-60 text-sm">Dzięki temu organizatorzy rozpoznają od kogo otrzymali tę pamiątkę</p>

                    {/*Ania - better with placeholder or not placeholder="Wpisz swoje imię" */}
                    <input
                        type="text"
                        className={"input input-primary w-full font-light " + (submitAttempted && !name ? 'input-error ' : '')}
                        onChange={val => setName(val.target.value)}
                    />
                    {submitAttempted && !name &&
                        <div className="label">
                            <span className="label-text-alt text-error">Uzupełnienie imienia jest wymagane, w celu zachowania prywatności możesz podać ksywkę.</span>
                        </div>
                    }

                    {/*<h2 className="font-light">Odrobina formalności</h2>*/}
                    <div className="form-control mt-6">
                        <label className="label cursor-pointer gap-6">
                            <input type="checkbox" className={"checkbox " + (submitAttempted && !consentGiven ? 'input-error ' : '')} onChange={(e) => setConsentGiven(e.target.checked)}/>
                            <span className="label-text text-xs opacity-60">Oświadczam, że zapoznałem/am się z <a className={'font-semibold'} href={'https://eventioo.com/pl/privacy'} target={'_blank'}>Polityką prywatności</a> oraz <a className={'font-semibold'} href={'https://eventioo.com/pl/terms-of-use'} target={'_blank'}>Warunkami użytkowania</a> Eventioo. Wyrażam zgodę na powyższe warunku, w szczególności w zakresie dotyczacym praw autorskich do udostępnionych multimediów</span>
                        </label>
                    </div>
                    {submitAttempted && !consentGiven &&
                        <div className="label">
                            <span className="label-text-alt text-error">Wyrażenie zgody na warunki użytkowania jest wymagane.</span>
                        </div>
                    }
                </div>

                {/* Ania: better without it?*/}
                {/*fixed bottom-0 left-0*/}

                <div className={'bg-base-100 w-full pt-4 pb-0'}>
                    {/*<input {...getInputProps()} />*/}
                    { isFormValid ? <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps({className: 'btn btn-primary w-full'})} />
                        <GradientButton label='Wgraj zdjęcia i filmy' icon='fi-sr-camera' />
                    </div> : <GradientButton disabled={submitAttempted && !isFormValid} onClick={() => setSubmitAttempted(true)} label='Wgraj zdjęcia i filmy' icon='fi-sr-camera' /> }

                    {/*<p>Drag 'n' drop some files here, or click to select files</p>*/}


                </div>

                {/*<div className='h-[118px]'></div>*/}
            </div>
            <div className='text-xs items-center text-center justify-center p-3 pb-4 flex flex-row gap-1'>
                <p className='text-gray-600'>Obsługiwane przez</p> <img className='h-[30px]' src={'https://cdn.eventioo.com/public/static/logo-email.png'} />
            </div>
        </div>
    );
};
