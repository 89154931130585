import React from 'react';
import {QrMemoriesContextDto} from "../App";
import {BetaDisclaimer} from "./BetaDisclaimer";
import {DemoDisclaimer} from "./DemoDisclaimer";

interface LargeHeaderProps {
    isMobile: boolean;
    context: QrMemoriesContextDto;
    onClick?: () => void;
    betaDisclaimer?: boolean;
    demoDisclaimer?: boolean;
}

export const LargeHeaderV2: React.FC<LargeHeaderProps> = ({ isMobile, context, onClick, betaDisclaimer, demoDisclaimer }: LargeHeaderProps) => {
    // const fallbackBackgroundUrl = 'https://images.pexels.com/photos/1024984/pexels-photo-1024984.jpeg?cs=srgb&dl=pexels-asadphoto-1024984.jpg&fm=jpg';
    // const fallbackBackgroundUrl = ''; // TODO
    // const cfg = context?.cfg;

    // TODO fallback bg as gradient
    //
    // return (<header onClick={() => onClick ? onClick() : null}>
    //     {betaDisclaimer && <BetaDisclaimer/> }
    //     {demoDisclaimer && <DemoDisclaimer/> }
    //     <div className='w-full relative bg-gradient-to-r from-orange-200 to-amber-200 bg-no-repeat h-[35vh] bg-center bg-cover -z-10' style={{
    //         backgroundPositionY: !isMobile ? '20%' : undefined,
    //         backgroundImage: cfg?.headerImageUrl ? `url(${isMobile && cfg?.headerImageUrlMobile ? cfg.headerImageUrlMobile : (cfg.headerImageUrl ? cfg.headerImageUrl : fallbackBackgroundUrl)})` : undefined
    //     }}>
    //         {cfg?.imageToTitleStyle === 'gradient' && <div className='h-64 absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white dark:to-base-100'></div> }
    //         {cfg?.imageToTitleStyle === 'rounded-overflow' && <div className='h-4 absolute bottom-0 left-0 right-0 border-b'></div> }
    //
    //     </div>
    //
    //     <div className={'p-4 pt-8 flex flex-col items-center justify-center' + (cfg?.imageToTitleStyle === 'rounded-overflow' ? ' rounded-t-[2rem] z-50 bg-white -mt-8 ' : '') }>
    //         <h1 className={(cfg?.titleSize || 'text-5xl') + ' ' + (cfg?.titleFont || 'great-vibes-regular') + " font-light text-center"}>{cfg?.title}</h1>
    //         { cfg?.subTitle && <h1 className={(cfg?.subTitleSize || 'text-3xl') + ' ' + (cfg?.subTitleFont || 'great-vibes-regular') +  " font-light text-center"}>{cfg?.subTitle}</h1>}
    //     </div>



    // </header>);

    const coverCfg = context.page.cover;
    const loading = !context;
    const imageOpacity = 100;
    const demoMode = false;
    const attachmentFixed = false;

    const getJustifyClass = () => {
        if (!coverCfg) return 'justify-end';
        switch (coverCfg.textPosition) {
            case 'start': return 'justify-start';
            case 'center': return 'justify-center';
            case 'end': return 'justify-end';
            default: return 'justify-end';
        }
    };

    const getBackgroundImage = () => {
        if (coverCfg?.coverImage?.croppedImageReadUrl) {
            return `url(${coverCfg.coverImage.croppedImageReadUrl})`;
        }
        if (loading || coverCfg) return undefined;
        return undefined;
        // return `url(${event?.type === 'wedding' ? WEDDING_DEFAULT_IMAGE_URL : DEFAULT_IMAGE_URL})`;
    };

    return (
        <div className={'aspect-[3/4] w-full'}>
            <div className={`relative h-full flex flex-col items-center bg-gray-300 group ${getJustifyClass()}`}>
                {(
                    <div
                        className={`absolute top-0 left-0 bottom-0 right-0 bg-cover bg-center flex items-center justify-center ${attachmentFixed ? 'bg-fixed' : ''}`}
                        style={{
                            opacity: `${coverCfg || loading ? imageOpacity : 40}%`,
                            backgroundImage: getBackgroundImage()
                        }}
                    >
                        {demoMode && !coverCfg?.coverImage?.croppedImageReadUrl && (
                            <div className="justify-self-center self-center text-sm text-gray-500 max-w-[200px] text-center flex flex-col">
                                {/*<Camera className="text-lg self-center" />*/}
                                <p>Skonfiguruj własną okładkę w&nbsp;widoku konfiguracji Panelu Gościa</p>
                            </div>
                        )}
                    </div>
                )}

                {coverCfg?.extraBackgroundLayer?.type && (
                    <div
                        className={`absolute top-0 left-0 bottom-0 right-0 ${
                            coverCfg.extraBackgroundLayer.type === 'black' ? 'bg-black' : 'bg-white'
                        }`}
                        style={{
                            opacity: `${coverCfg.extraBackgroundLayer.opacity || 0}%`
                        }}
                    />
                )}

                <div
                    className={`flex flex-col text-white z-30 ${
                        coverCfg?.textPosition === 'start' ? 'pt-20' : ''
                    } ${
                        coverCfg?.textPosition === 'end' ? 'pb-8' : ''
                    }`}
                >
                    {coverCfg?.title?.text && (
                        <h1
                            style={{ color: coverCfg.title.color || 'black' }}
                            className={`${coverCfg.title.font || 'great-vibes-regular'} ${
                                coverCfg.title.size || 'text-5xl'
                            } font-light text-center`}
                        >
                            {coverCfg.title.text}
                        </h1>
                    )}

                    {coverCfg?.subTitle?.text && (
                        <h1
                            style={{ color: coverCfg.subTitle.color || 'black' }}
                            className={`${coverCfg.subTitle.font || 'great-vibes-regular'} ${
                                coverCfg.subTitle.size || 'text-3xl'
                            } font-light text-center`}
                        >
                            {coverCfg.subTitle.text}
                        </h1>
                    )}
                </div>
            </div>
        </div>
    );
};




