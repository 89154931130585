import React, {ReactNode, useEffect} from 'react';
import {QrMemoriesContextDto} from "../App";

interface SmallHeaderProps {
    children?: ReactNode;
    context: QrMemoriesContextDto;
}

export const SmallHeaderV2: React.FC<SmallHeaderProps> = ({ context, children }: SmallHeaderProps) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const coverCfg = context?.page?.cover;

    // TODO, actual colors? but what to do when its not visible on white screen?
    const titleColor = 'black' || coverCfg?.title?.color;
    const subTitleColor = 'black' || coverCfg?.subTitle?.color;

    return (<header className=' border-b px-4 pb-4'>
        <div className='pt-8 flex flex-col items-center justify-center '>
            <h1 style={{ color: titleColor }} className={(coverCfg?.title?.size || 'text-5xl') + ' ' + (coverCfg?.title?.font || 'great-vibes-regular') + " font-light text-center"}>{coverCfg?.title?.text}</h1>
            { coverCfg?.subTitle?.text && <h1 style={{ color: subTitleColor }} className={(coverCfg?.subTitle?.size || 'text-3xl') + ' ' + (coverCfg?.subTitle?.font || 'great-vibes-regular') + " font-light text-center"}>{coverCfg?.subTitle?.text}</h1> }
        </div>
        {children}
    </header>);
};

